// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { Indexable } from 'reducers';
export type NonNullableProperties<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

export type OptionalNonNullableProperties<T> = {
    [P in keyof T]?: NonNullable<T[P]>;
};

export function filterNull<Type>(obj: Type): OptionalNonNullableProperties<Type> {
    const filteredObject = { ...obj } as OptionalNonNullableProperties<Type>;
    if (filteredObject) {
        for (const key of Object.keys(filteredObject)) {
            if ((filteredObject as Indexable)[key] === null) {
                delete (filteredObject as Indexable)[key];
            }
        }
    }
    return filteredObject;
}
