// Copyright (C) 2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { PaymentsActions, PaymentsActionTypes } from 'actions/payment-actions';
import { produce } from 'immer';
import { PaymentState } from 'reducers';

const defaultState: PaymentState = {
    query: {
        page: 1,
        sort: null,
    },
    fetching: false,
    count: 0,
    payments: [],
};
export default (state: PaymentState = defaultState, action: PaymentsActions): PaymentState => {
    switch (action.type) {
        case PaymentsActionTypes.GET_PAYMENTS: {
            return produce(state, (draft) => {
                draft.fetching = true;
                Object.assign(draft.query, action.payload.query);
                return draft;
            });
        }
        case PaymentsActionTypes.GET_PAYMENTS_SUCCESS: {
            return produce(state, (draft) => {
                draft.fetching = false;
                draft.count = action.payload.payments.count;
                draft.payments = draft.payments.filter((oldPay): boolean =>
                    action.payload.payments.some((newPay): boolean => newPay.id === oldPay.id),
                );
                draft.payments.push(...action.payload.payments);
                return draft;
            });
        }
        case PaymentsActionTypes.GET_PAYMENTS_FAILED: {
            return produce(state, (draft) => {
                draft.fetching = false;
                return draft;
            });
        }
        case PaymentsActionTypes.CREATE_PAYMENT_SUCCESS: {
            return produce(state, (draft) => {
                draft.fetching = false;
                draft.count += 1;
                draft.payments.unshift(action.payload.newPayment);
                return draft;
            });
        }
        default: {
            return state;
        }
    }
};
