// Copyright (C) 2024 CVAT.ai Corporation

import React from 'react';
import { CombinedState } from 'reducers';
import GlobalHotKeys, { KeyMapItem } from 'utils/mousetrap-react';
import CVATTooltip from 'components/common/cvat-tooltip';
import Icon from '@ant-design/icons';
import { InvertImageIcon } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleInvertBgImageColor } from 'actions/settings-actions';

// SPDX-License-Identifier: MIT
export interface Props {
    shortcuts: {
        INVERT_BACKGROUND: {
            details: KeyMapItem;
            displayValue: string;
        };
    };
}

function InvertBackgroundControl(props: Props): JSX.Element {
    const { shortcuts } = props;
    const dispatch = useDispatch();
    const imageInvertMode = useSelector((state: CombinedState) => state.settings.player.imageInvertMode);
    const dynamicIconProps = imageInvertMode ?
        {
            className: 'cvat-invert-image-control cvat-active-canvas-control',
            onClick: (): void => {
                dispatch(toggleInvertBgImageColor(!imageInvertMode));
            },
        } :
        {
            className: 'cvat-invert-image-control',
            onClick: (): void => {
                dispatch(toggleInvertBgImageColor(!imageInvertMode));
            },
        };

    return (
        <>
            <GlobalHotKeys
                keyMap={{ INVERT_BACKGROUND: shortcuts.INVERT_BACKGROUND.details }}
                handlers={{
                    INVERT_BACKGROUND: (event: KeyboardEvent | undefined) => {
                        if (event) event.preventDefault();

                        dispatch(toggleInvertBgImageColor(!imageInvertMode));
                    },
                }}
            />
            <CVATTooltip
                title={`Invert background image color ${shortcuts.INVERT_BACKGROUND.displayValue}`}
                placement='right'
            >
                <Icon
                    {...dynamicIconProps}
                    component={InvertImageIcon}
                    size={30}
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(toggleInvertBgImageColor(!imageInvertMode));
                    }}
                />
            </CVATTooltip>
        </>
    );
}

export default React.memo(InvertBackgroundControl);
