// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { memo, useEffect, useRef, useState } from 'react';
import Select, { DefaultOptionType, RefSelectProps, SelectProps } from 'antd/lib/select';
import { Label } from 'cvat-core-wrapper';

interface Props extends SelectProps<any, any> {
    labels: Label[];
    value: any | number | null;
    onChange: (label: Label | undefined) => void;
    onEnterPress?: (labelID: Label) => void;
}

function LabelSelector(props: Props): JSX.Element {
    const { labels, value, onChange, onEnterPress, ...rest } = props;
    const [searchVal, setSearchVal] = useState<string>('');

    const dynamicProps = value
        ? {
              value: typeof value === 'number' ? value : value.id,
          }
        : {};

    const [enterPressed, setEnterPressed] = useState(false);
    const selectRef = useRef<RefSelectProps>(null);

    useEffect(() => {
        if (enterPressed) {
            // onEnterPress(value);

            onChange(undefined);
            setEnterPressed(false);
            selectRef.current?.blur();
        }
    }, [enterPressed, selectRef]);
    useEffect(() => {
        console.log('MOUNTED');
    }, []);

    useEffect(() => {
        console.log('CHANGES HAPPENED');
    }, [labels, value, onChange, onEnterPress]);

    return (
        <Select
            ref={selectRef}
            virtual={false}
            {...rest}
            {...dynamicProps}
            showSearch
            searchValue={searchVal}
            onSearch={(val) => {
                setSearchVal(val);
            }}
            filterOption={(input: string, option?: DefaultOptionType) => {
                if (option) {
                    const { children } = option;
                    if (children && typeof children === 'string')
                        return (children as string).toLowerCase().includes(input.toLowerCase());
                }

                return false;
            }}
            filterSort={(optA: DefaultOptionType) => (((optA.children ?? '') as string).startsWith(searchVal) ? -1 : 1)}
            onChange={(newValue: string) => {
                const [label] = labels.filter((_label: Label): boolean => _label.id === +newValue);
                onChange(label);
                selectRef.current?.blur();
            }}
            onInputKeyDown={(event) => {
                if (onEnterPress) {
                    setEnterPressed(event.key === 'Enter' || event.key === 'Escape');
                }
            }}
            onSelect={(val: string, option: DefaultOptionType) => {
                if (option.value && typeof option.value === 'number') {
                    const label = labels.find((_label: Label): boolean => _label.id === option.value);

                    onChange(label);
                } else onChange(undefined);
                selectRef.current?.blur();
            }}
            onBlur={() => {
                console.log('BLURED');
            }}
            defaultActiveFirstOption={true}
        >
            {labels.map((label: Label) => (
                <Select.Option title={label.name} key={label.id} value={label.id}>
                    {label.name}
                </Select.Option>
            ))}
        </Select>
    );
}

export default memo(LabelSelector);
