// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    useRef, useEffect, useState, useMemo,
} from 'react';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import CVATLoadingSpinner from 'components/common/loading-spinner';
import { getCore } from 'cvat-core-wrapper';
import { TaskHistory } from 'cvat-core/src/session';

interface TaskHistoryModalComponentProps {
    taskId: number;
    visible: boolean;
    setVisible: React.Dispatch<boolean>;
}

const core = getCore();

const historyRowProps = {
    style: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 12,
    },
} as const;
const historyFirstTextProps = {
    style: {
        flex: '1',
        fontWeight: 600,
    },
} as const;
const historyTextProps = {
    style: {
        flex: '1',
        '.positive': { color: 'limegreen' },
    },
} as const;

export default function TaskHistoryModal(props: TaskHistoryModalComponentProps): JSX.Element {
    const { taskId, visible, setVisible } = props;
    const onClose = (): void => setVisible(false);
    const [taskHistoryFetching, setTaskHistoryFetching] = useState(false);
    const [historyItems, setHistoryItems] = useState<TaskHistory[]>([]);
    const mounted = useRef(false);

    const taskHistories = useMemo(() => (!taskHistoryFetching && historyItems.length === 0 ?
        (<div>No History Found</div>) :
        historyItems.map((item) => {
            const date = new Date(item.updatedDate);
            const dateString = `${date.toLocaleDateString()}`;
            return (
                <div {...historyRowProps} key={`history-${item.id}`}>
                    <div {...historyFirstTextProps}>
                        {`Job # ${item.jobId} updated ${dateString}`}
                    </div>
                    {/* <div {...historyTextProps}>
                        {item.updatedDate}
                    </div> */}
                    {item.assignee !== null && (
                        <div {...historyTextProps}>
                            {`Assignee changed to ${item.assignee}`}
                        </div>
                    )}
                    {item.stage !== null && (
                        <div {...historyTextProps}>
                            {`Stage changed to ${item.stage}`}
                        </div>
                    )}
                    {item.state !== null && (
                        <div {...historyTextProps}>
                            {`Stage changed to ${item.state}`}
                        </div>
                    )}
                    {Boolean(item.annotatedFrames) && (
                        <div {...historyTextProps}>
                            {`Annotated Frames:  ${item.annotatedFrames}`}
                            {item.annotatedFramesChange && (
                                <span className={`history-change ${item.annotatedFramesChange > 0 ? 'positive' : 'negative'}`}>
                                    {item.annotatedFramesChange >= 0 ? '+' : ''}
                                    {item.annotatedFramesChange ?? 'No Change'}
                                </span>
                            )}
                        </div>
                    )}
                    {Boolean(item.annotationCount) && (
                        <div {...historyTextProps}>
                            {`Total Annotations:  ${item.annotationCount}`}
                            {item.annotationCountChange && (
                                <span className={`history-change ${item.annotationCountChange > 0 ? 'positive' : 'negative'}`}>
                                    {item.annotationCountChange >= 0 ? '+' : ''}
                                    {item.annotationCountChange ?? 'No Change'}
                                </span>
                            )}
                        </div>
                    )}
                    {Boolean(item.openIssues) && (
                        <div {...historyTextProps}>
                            {`Open Issues:  ${item.openIssues}`}
                            {item.openIssuesChange && (
                                <span className={`history-change ${item.openIssuesChange < 0 ? 'positive' : 'negative'}`}>
                                    {item.openIssuesChange >= 0 ? '+' : ''}
                                    {item.openIssuesChange ?? 'No Change'}
                                </span>
                            )}
                        </div>
                    )}
                    {Boolean(item.resolvedIssues) && (
                        <div {...historyTextProps}>
                            {`Resolved Issues:  ${item.resolvedIssues}`}
                            {item.resolvedIssuesChange && (
                                <span className={`history-change ${item.resolvedIssuesChange > 0 ? 'positive' : 'negative'}`}>
                                    {item.resolvedIssuesChange >= 0 ? '+' : ''}
                                    {item.resolvedIssuesChange ?? 'No Change'}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            );
        })),
    [historyItems, taskHistoryFetching]);

    useEffect(() => {
        if (visible && Number.isInteger(taskId)) {
            setTaskHistoryFetching(true);
            core.taskHistory.get({ taskID: taskId })
                .then((histories: TaskHistory[]) => {
                    if (mounted.current) {
                        setHistoryItems(histories);
                    }
                })
                .catch((error: Error) => notification.error({
                    message: 'Could not fetch task from the server',
                    description: error.toString(),
                })).finally(() => {
                    if (mounted.current) {
                        setTaskHistoryFetching(false);
                    }
                });
        }
    }, [visible, taskId]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);
    return (
        <Modal
            visible={visible}
            onOk={onClose}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            title={(<span>Task History</span>)}
            className='cvat-task-history-modal'
        >
            { taskHistoryFetching && <CVATLoadingSpinner size='large' /> }
            { taskHistories }
        </Modal>
    );
}
