// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CombinedState } from 'reducers';
import moment from 'moment';
import { Col, Row } from 'antd/lib/grid';
import Card from 'antd/lib/card';
import Text from 'antd/lib/typography/Text';
import Tag from 'antd/lib/tag';
import Select from 'antd/lib/select';
import Dropdown from 'antd/lib/dropdown';
import Icon from '@ant-design/icons';
import {
    BorderOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    MoreOutlined,
    ProjectOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons/lib/icons';
import { DurationIcon, FramesIcon } from 'icons';
import { Job, JobStage, JobState, JobType, Payment, Task, User } from 'cvat-core-wrapper';
import { useIsMounted } from 'utils/hooks';
import UserSelector from 'components/task-page/user-selector';
import CVATTooltip from 'components/common/cvat-tooltip';
import JobActionsMenu from './job-actions-menu';
import { getPaymentsAsync } from 'actions/payment-actions';
import Progress from 'antd/lib/progress';

interface Props {
    job: Job;
    task: Task;
    onJobUpdate: (job: Job) => void;
    onUpdatePaidJob?: (job: Job) => void;
}

function ReviewSummaryComponent({ jobInstance }: { jobInstance: any }): JSX.Element {
    const [summary, setSummary] = useState<Record<string, any> | null>(null);
    const [error, setError] = useState<any>(null);
    const isMounted = useIsMounted();

    useEffect(() => {
        setError(null);
        jobInstance
            .issues(jobInstance.id)
            .then((issues: any[]) => {
                if (isMounted()) {
                    setSummary({
                        issues_unsolved: issues.filter((issue) => !issue.resolved).length,
                        issues_resolved: issues.filter((issue) => issue.resolved).length,
                    });
                }
            })
            .catch((_error: any) => {
                if (isMounted()) {
                    // eslint-disable-next-line
                    console.log(_error);
                    setError(_error);
                }
            });
    }, []);

    if (!summary) {
        if (error) {
            if (error.toString().includes('403')) {
                return <p>You do not have permissions</p>;
            }

            return <p>Could not fetch, check console output</p>;
        }

        return (
            <>
                <p>Loading.. </p>
                <LoadingOutlined />
            </>
        );
    }

    return (
        <table className='cvat-review-summary-description'>
            <tbody>
                <tr>
                    <td>
                        <Text strong>Unsolved issues</Text>
                    </td>
                    <td>{summary.issues_unsolved}</td>
                </tr>
                <tr>
                    <td>
                        <Text strong>Resolved issues</Text>
                    </td>
                    <td>{summary.issues_resolved}</td>
                </tr>
            </tbody>
        </table>
    );
}
function PaymentSummaryComponent({ payment }: { payment: Payment }): JSX.Element {
    return (
        <table className='cvat-payment-summary-description'>
            <tbody>
                <tr>
                    <td>
                        <Text strong>Payment date</Text>
                    </td>
                    <td>{moment(payment.date).format('MM DD YYYY')}</td>
                </tr>
                <tr>
                    <td>
                        <Text strong>Assignee</Text>
                    </td>
                    <td>{payment.annotator ? `${payment.annotator.username} ` : 'No annotator assigned'}</td>
                </tr>
                <tr>
                    <td>
                        <Text strong>Amount</Text>
                    </td>
                    <td>${payment.amount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>
                        <Text strong>Description</Text>
                    </td>
                    <td className='description'>{payment.description}</td>
                </tr>
            </tbody>
        </table>
    );
}

function JobItem(props: Props): JSX.Element {
    const { job, task, onJobUpdate, onUpdatePaidJob } = props;
    const { stage, id } = job;
    const created = moment(job.createdDate);
    const updated = moment(job.updatedDate);
    const now = moment(moment.now());
    const { deletes, payments, fetchingPayment } = useSelector((state: CombinedState) => ({
        deletes: state.jobs.activities.deletes,
        payments: state.payments.payments,
        fetchingPayment: job.state === JobState.PAID && state.payments.fetching,
    }));
    const history = useHistory();
    const dispatch = useDispatch();
    const deleted = id in deletes ? deletes[id] === true : false;
    const style = {};
    if (deleted) {
        (style as any).pointerEvents = 'none';
        (style as any).opacity = 0.5;
    }

    const paymentDetail: Payment | undefined = payments.find((payment): boolean => payment.job === job.id);

    // get payment info when job state is paid
    useEffect(() => {
        if (job.state === JobState.PAID) {
            dispatch(getPaymentsAsync({ jobID: job.id, page: 1 }));
        }
    }, [job.state]);
    useEffect(() => {
        console.log('JOB:', job.framesAnnotated);
    }, [job]);

    return (
        <Col span={24}>
            <Card
                className='cvat-job-item'
                style={{ ...style }}
                data-row-id={job.id}
                onClick={() => history.replace(`/tasks/${job.taskId}/jobs/${job.id}`)}
            >
                <Row>
                    <Col span={7}>
                        <Row>
                            <Col>
                                <Link to={`/tasks/${job.taskId}/jobs/${job.id}`}>{`Job #${job.id}`}</Link>
                            </Col>
                            {job.type === JobType.GROUND_TRUTH && (
                                <Col offset={1}>
                                    <Tag color='#ED9C00'>Ground truth</Tag>
                                </Col>
                            )}
                        </Row>
                        <Row className='cvat-job-item-dates-info'>
                            <Col>
                                <Text>Created on </Text>
                                <Text type='secondary'>{`${created.format('MMMM Do YYYY HH:mm')}`}</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text>Last updated </Text>
                                <Text type='secondary'>{`${updated.format('MMMM Do YYYY HH:mm')}`}</Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={7}>
                        <Row className='cvat-job-item-selects' justify='space-between'>
                            <Col>
                                <Row>
                                    <Col className='cvat-job-item-select'>
                                        <Row>
                                            <Text>Assignee:</Text>
                                        </Row>
                                        <UserSelector
                                            className='cvat-job-assignee-selector'
                                            value={job.assignee}
                                            onSelect={(user: User | null): void => {
                                                if (job?.assignee?.id === user?.id) return;
                                                job.assignee = user;
                                                onJobUpdate(job);
                                            }}
                                        />
                                    </Col>
                                    <Col className='cvat-job-item-select'>
                                        <Row justify='space-between' align='middle'>
                                            <Col>
                                                <Text>Stage:</Text>
                                            </Col>
                                            <Col>
                                                <CVATTooltip title={<ReviewSummaryComponent jobInstance={job} />}>
                                                    <QuestionCircleOutlined />
                                                </CVATTooltip>
                                            </Col>
                                        </Row>
                                        <Select
                                            className='cvat-job-item-stage'
                                            value={stage}
                                            onChange={(newValue: JobStage) => {
                                                job.stage = newValue;
                                                onJobUpdate(job);
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Select.Option value={JobStage.ANNOTATION}>
                                                {JobStage.ANNOTATION}
                                            </Select.Option>
                                            <Select.Option value={JobStage.VALIDATION}>
                                                {JobStage.VALIDATION}
                                            </Select.Option>
                                            <Select.Option value={JobStage.ACCEPTANCE}>
                                                {JobStage.ACCEPTANCE}
                                            </Select.Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <Row className='cvat-job-item-details'>
                            <Col>
                                <ProjectOutlined />
                                <Text>State: </Text>

                                {job.state === JobState.PAID &&
                                    (fetchingPayment ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <CVATTooltip
                                            title={
                                                paymentDetail ? (
                                                    <PaymentSummaryComponent payment={paymentDetail} />
                                                ) : (
                                                    'Failed to get payment detail'
                                                )
                                            }
                                            overlayStyle={{
                                                maxWidth: 400,
                                                display: 'flex',
                                            }}
                                        >
                                            <Text type={'success'} className='cvat-job-item-state'>
                                                {`${job.state.charAt(0).toUpperCase() + job.state.slice(1)}`}
                                            </Text>
                                            <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                                        </CVATTooltip>
                                    ))}

                                {job.state !== JobState.PAID && (
                                    <Text type='secondary' className='cvat-job-item-state'>
                                        {`${job.state.charAt(0).toUpperCase() + job.state.slice(1)}`}
                                    </Text>
                                )}
                            </Col>
                            <Col offset={3}>
                                <Icon component={DurationIcon} />
                                <Text>Duration: </Text>
                                <Text type='secondary'>{`${moment.duration(now.diff(created)).humanize()}`}</Text>
                            </Col>
                        </Row>

                        <Progress
                            percent={parseFloat(((job.framesAnnotated / job.frameCount) * 100).toFixed(2))}
                            success={{
                                percent: (job.framesAnnotated / job.frameCount) * 100,
                            }}
                            size={'small'}
                        />

                        <Row justify='space-between'>
                            <Col className='annotation-detail-text'>
                                <Text strong>Total Frame: </Text>
                                <Text type='secondary' strong>{`${job.frameCount}`}</Text>
                            </Col>
                            <Col className='annotation-detail-text'>
                                <Text strong>Annotated: </Text>
                                <Text className='annotated-text' strong>{`${job.framesAnnotated}`}</Text>
                            </Col>
                            <Col className='annotation-detail-text'>
                                <Text strong>Unannotated: </Text>
                                <Text className='unannotated-text' strong>{`${
                                    job.frameCount - job.framesAnnotated
                                }`}</Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Dropdown
                    trigger={['click']}
                    destroyPopupOnHide
                    overlay={<JobActionsMenu job={job} onJobUpdate={onJobUpdate} onUpdatePaidJob={onUpdatePaidJob} />}
                >
                    <MoreOutlined className='cvat-job-item-more-button' onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            </Card>
        </Col>
    );
}

export default React.memo(JobItem);
