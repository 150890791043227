// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';
import { Col, Row } from 'antd/lib/grid';
import Icon from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { DoxleIcon, SignInPageEmailIcon } from 'icons';
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'actions/auth-actions';
import { motion, Variants } from 'framer-motion';
import { CombinedState } from 'reducers';

interface SignInLayoutComponentProps {
    children: JSX.Element | JSX.Element[];
}

interface Sizes {
    xs?: { span: number };
    sm?: { span: number };
    md?: { span: number };
    lg?: { span: number };
    xl?: { span: number };
    xxl?: { span: number };
}

interface FormSizes {
    wrapper: Sizes;
    form: Sizes;
}

export const formSizes: FormSizes = {
    wrapper: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 15 },
        xxl: { span: 12 },
    },
    form: {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 14 },
        lg: { span: 14 },
        xl: { span: 18 },
        xxl: { span: 18 },
    },
};
const AnimateCol = motion(Col);
function SignInLayout(props: SignInLayoutComponentProps): JSX.Element {
    const { children } = props;
    const { Content, Header } = Layout;
    const dispatch = useDispatch();
    const showLoginForm = useSelector((state: CombinedState) => state.auth.showLoginForm);
    const titleSizes = {
        xs: { span: 20 },
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
        xl: { span: 8 },
        xxl: { span: 10 },
    };
    const logoSize = {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 15 },
        xxl: { span: 12 },
    };
    const toggleVariants: Variants = {
        enter: (direction: number) => ({
            y: direction > 0 ? 1000 : -1000,
            opacity: 0,
            rotateX: direction > 0 ? -90 : 90,
        }),
        center: {
            y: 0,
            opacity: 1,
            rotateX: 0,
            transition: {
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.5 },
                rotateX: { duration: 0.5 },
            },
        },
        exit: (direction: number) => ({
            y: direction < 0 ? 1000 : -1000,
            opacity: 0,
            rotateX: direction < 0 ? -90 : 90,
            transition: {
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.5 },
                rotateX: { duration: 0.5 },
            },
        }),
    };
    return (
        <Layout className='cvat-login-page-layout'>
            {/* <SVGSigningBackground className='cvat-signing-background' /> */}
            <Header className='cvat-signing-header'>
                <Row justify='end' align='middle' {...logoSize}>
                    <Tooltip title='Email'>
                        <Icon className='cvat-logo-icon' component={SignInPageEmailIcon} />
                    </Tooltip>
                </Row>
            </Header>
            <Layout className='cvat-signing-layout'>
                <Content style={{ height: '100%', width: '100%', maxWidth: 1600 }}>
                    <Row justify='center' align='middle' style={{ height: '100%', width: 'inherit' }}>
                        <AnimateCol
                            {...titleSizes}
                            className='cvat-signing-title'
                            custom={1}
                            variants={toggleVariants}
                            initial='enter'
                            animate='center'
                            exit='exit'
                            style={{ transformOrigin: 'bottom center' }}
                        >
                            <Icon component={DoxleIcon} className='doxle-icon' />
                            <Title className='head-title-text'>WHERE BUILDING</Title>
                            <Title className='head-title-text'>MEETS AI</Title>
                            <Paragraph className='paragraph-text'>
                                Doxle.ai focuses on solving the complexities of measurements, budgeting, and engineering
                                by autonomously creating IFC components. Our technology eliminates the hours spent on
                                tedious tasks such as take-offs, cost management, 3d services, calculating structural
                                loads etc.
                            </Paragraph>
                            {!showLoginForm && (
                                <Button
                                    className='sign-in-button'
                                    onClick={() => dispatch(authActions.showLoginForm())}
                                >
                                    Sign in
                                </Button>
                            )}
                        </AnimateCol>
                        {children}
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
}

export default SignInLayout;
