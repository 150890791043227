// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { memo, SVGProps } from 'react';

import { HTMLMotionProps, motion } from 'framer-motion';
import SVGCVATLogo from './assets/cvat-logo.svg';
import SVGCVATIcon from './assets/cvat-icon.svg';
import SVGCursorIcon from './assets/cursor-icon.svg';
import SVGMoveIcon from './assets/move-icon.svg';
import SVGRotateIcon from './assets/rotate-icon.svg';
import SVGFitIcon from './assets/fit-to-window-icon.svg';
import SVGZoomIcon from './assets/zoom-icon.svg';
import SVGRectangleIcon from './assets/rectangle-icon.svg';
import SVGPolygonIcon from './assets/polygon-icon.svg';
import SVGPointIcon from './assets/point-icon.svg';
import SVGEllipseIcon from './assets/ellipse-icon.svg';
import SVGPolylineIcon from './assets/polyline-icon.svg';
import SVGTagIcon from './assets/tag-icon.svg';
import SVGMergeIcon from './assets/merge-icon.svg';
import SVGGroupIcon from './assets/group-icon.svg';
import SVGSplitIcon from './assets/split-icon.svg';
import SVGMainMenuIcon from './assets/main-menu-icon.svg';
import SVGSaveIcon from './assets/save-icon.svg';
import SVGUndoIcon from './assets/undo-icon.svg';
import SVGRedoIcon from './assets/redo-icon.svg';
import SVGFirstIcon from './assets/first-icon.svg';
import SVGBackJumpIcon from './assets/back-jump-icon.svg';
import SVGPreviousIcon from './assets/previous-icon.svg';
import SVGPreviousFilteredIcon from './assets/previous-filtered-icon.svg';
import SVGPreviousEmptyIcon from './assets/previous-empty-icon.svg';
import SVGPlayIcon from './assets/play-icon.svg';
import SVGPauseIcon from './assets/pause-icon.svg';
import SVGNextIcon from './assets/next-icon.svg';
import SVGNextFilteredIcon from './assets/next-filtered-icon.svg';
import SVGNextEmptyIcon from './assets/next-empty-icon.svg';
import SVGForwardJumpIcon from './assets/forward-jump-icon.svg';
import SVGLastIcon from './assets/last-icon.svg';
import SVGInfoIcon from './assets/info-icon.svg';
import SVGFullscreenIcon from './assets/fullscreen-icon.svg';
import SVGObjectOutsideIcon from './assets/object-outside-icon.svg';
import SVGBackgroundIcon from './assets/background-icon.svg';
import SVGForegroundIcon from './assets/foreground-icon.svg';
import SVGCubeIcon from './assets/cube-icon.svg';
import SVGSkeletonIcon from './assets/skeleton-icon.svg';
import SVGResetPerspectiveIcon from './assets/reset-perspective.svg';
import SVGColorizeIcon from './assets/colorize-icon.svg';
import SVGAITools from './assets/ai-tools-icon.svg';
import SVGBrain from './assets/brain.svg';
import SVGOpenCV from './assets/opencv.svg';
import SVGFilterIcon from './assets/filter-icon.svg';
import SVGCVATAzureProvider from './assets/vscode-icons_file-type-azure.svg';
import SVGCVATS3Provider from './assets/S3.svg';
import SVGCVATGoogleCloudProvider from './assets/google-cloud.svg';
import SVGRestoreIcon from './assets/restore-icon.svg';
import SVGBrushIcon from './assets/brush-icon.svg';
import SVGEraserIcon from './assets/eraser-icon.svg';
import SVGPolygonPlusIcon from './assets/polygon-plus.svg';
import SVGPolygonMinusIcon from './assets/polygon-minus.svg';
import SVGMultiPlusIcon from './assets/multi-plus-icon.svg';
import SVGBackArrowIcon from './assets/back-arrow-icon.svg';
import SVGClearIcon from './assets/clear-icon.svg';
import SVGShowPasswordIcon from './assets/show-password.svg';
import SVGPlusIcon from './assets/plus-icon.svg';
import SVGCheckIcon from './assets/check-icon.svg';
import SVGGuideIcon from './assets/guide-icon.svg';
import SVGDurationIcon from './assets/duration-icon.svg';
import SVGFrameCountIcon from './assets/frame-count-icon.svg';
import SVGFramesIcon from './assets/frames-icon.svg';
import SVGConflictIcon from './assets/conflict.svg';
import SVGShowGroundTruthIcon from './assets/show-gt-icon.svg';
import SVGJoinIcon from './assets/join-icon.svg';
import SVGSliceIcon from './assets/slice-icon.svg';
import SVGSignInEmailIcon from './assets/signin-email-icon.svg';
import SVGDoxleIcon from './assets/doxle-logo.svg';
import SVGDoxleHeaderIcon from './assets/doxle-header-icon.svg';
import SVGInvertBgImage from './assets/invert-bg-icon.svg';
import SVGPaidIcon from './assets/paid-icon.svg';

export const CVATLogo = React.memo((): JSX.Element => <SVGCVATLogo />);
export const SignInPageEmailIcon = React.memo((): JSX.Element => <SVGSignInEmailIcon />);
export const DoxleIcon = React.memo((): JSX.Element => <SVGDoxleIcon />);
export const DoxleHeaderIcon = React.memo((): JSX.Element => <SVGDoxleHeaderIcon />);
export const SignInBanner = memo(
    ({
        wrapperProps,
        ...props
    }: SVGProps<SVGSVGElement> & {
        wrapperProps?: React.HTMLAttributes<HTMLDivElement> & HTMLMotionProps<'div'>;
    }) => (
        <motion.div {...wrapperProps}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                height='100%'
                viewBox='0 0 454 501'
                fill='none'
                {...props}
            >
                <path stroke='#fff' strokeWidth={1.5} d='M6.418 7.25h256m-244 10h244' opacity={0.75} />
                <path
                    stroke='#fff'
                    d='M303.418 17.5h76m-68-11h69M17.918 18v26m-11-37v37m1 106v211m10-211v213m430.5 137.5h-441m440-10h-430m-9.5-31.5v42m10-42v31m431-195v136m-12-136v136m12-424v66m-.5-65.5h-27m15.5 10.5v55m-.5-54.5h-15'
                    opacity={0.75}
                />
                <g opacity={0.75}>
                    <path stroke='#fff' d='M19.139 214.805v-37.804h37.804v37.804z' />
                    <rect
                        width={29.804}
                        height={29.804}
                        x={23.139}
                        y={210.805}
                        fill='#84A2FF'
                        fillOpacity={0.6}
                        rx={3.5}
                        transform='rotate(-90 23.139 210.805)'
                    />
                    <rect
                        width={29.804}
                        height={29.804}
                        x={23.139}
                        y={210.805}
                        stroke='#fff'
                        rx={3.5}
                        transform='rotate(-90 23.139 210.805)'
                    />
                    <rect
                        width={7}
                        height={7}
                        x={34.541}
                        y={199.402}
                        stroke='#848484'
                        rx={3.5}
                        transform='rotate(-90 34.541 199.402)'
                    />
                    <path stroke='#848484' strokeDasharray='2 1' d='m38.041 180.5 8 15m-8-15-8 15m8-15v15' />
                </g>
                <g opacity={0.75}>
                    <path stroke='#fff' d='M453.418 294.5v-4h-9v4z' />
                    <mask id='a' fill='#fff'>
                        <path d='M453.918 290V78h-5.5v212h5.5Z' />
                    </mask>
                    <path fill='#fff' d='M452.918 290V78h2v212h-2Zm-3.5-212v212h-2V78h2Z' mask='url(#a)' />
                    <mask id='b' fill='#fff'>
                        <path d='M448.418 290V78h-4.5v212h4.5Z' />
                    </mask>
                    <path fill='#fff' d='M444.918 78v212h-2V78h2Z' mask='url(#b)' />
                    <path stroke='#fff' d='M453.418 77.5v-4h-9v4z' />
                </g>
                <g opacity={0.75}>
                    <path stroke='#fff' d='M420.918 1h-4v9h4z' />
                    <mask id='c' fill='#fff'>
                        <path d='M416.418.5h-37V6h37V.5Z' />
                    </mask>
                    <path fill='#fff' d='M416.418 1.5h-37v-2h37v2Zm-37 3.5h37v2h-37V5Z' mask='url(#c)' />
                    <mask id='d' fill='#fff'>
                        <path d='M416.418 6h-37v4.5h37V6Z' />
                    </mask>
                    <path fill='#fff' d='M379.418 9.5h37v2h-37v-2Z' mask='url(#d)' />
                </g>
                <g opacity={0.75}>
                    <mask id='e' fill='#fff'>
                        <path d='M444.418 295V73h-8v222h8Z' />
                    </mask>
                    <path fill='#2D3652' d='M444.418 295V73h-8v222h8Z' />
                    <path
                        fill='#fff'
                        d='M436.418 73h-1v-1h1v1Zm0 222v1h-1v-1h1Zm8-221h-8v-2h8v2Zm-7-1v222h-2V73h2Zm-1 221h8v2h-8v-2Z'
                        mask='url(#e)'
                    />
                </g>
                <g opacity={0.75}>
                    <path fill='#4752F8' fillOpacity={0.5} stroke='#fff' d='M310.93 1.5h-3.41v7.819h3.41z' />
                    <path fill='#4752F8' fillOpacity={0.5} d='M307.02 1h-40.568v8.819h40.568z' />
                    <mask id='f' fill='#fff'>
                        <path d='M307.02 1h-40.568v5.5h40.568V1Z' />
                    </mask>
                    <path
                        fill='#fff'
                        d='M307.02 2h-40.568V0h40.568v2Zm-40.568 3.5h40.568v2h-40.568v-2Z'
                        mask='url(#f)'
                    />
                    <mask id='g' fill='#fff'>
                        <path d='M307.02 6.5h-40.568V11h40.568V6.5Z' />
                    </mask>
                    <path fill='#fff' d='M266.452 10h40.568v2h-40.568v-2Z' mask='url(#g)' />
                    <path
                        fill='#4752F8'
                        fillOpacity={0.5}
                        stroke='#fff'
                        d='M265.953 1.5h-3.41v7.819h3.41zm44.977 8.82h-48.387v7.82h48.387v-7.82Z'
                    />
                </g>
                <g opacity={0.75}>
                    <path fill='#4652FF' fillOpacity={0.5} stroke='#fff' d='M383.246 1.5h-3.41v7.819h3.41z' />
                    <mask id='h' fill='#fff'>
                        <path d='M421.668 9.82h-42.331v8.82h42.331V9.82Z' />
                    </mask>
                    <path fill='#4652FF' fillOpacity={0.5} d='M421.668 9.82h-42.331v8.82h42.331V9.82Z' />
                    <path
                        fill='#fff'
                        d='M379.337 18.64v1h-1v-1h1Zm42.331 0h1v1h-1v-1Zm-41.331-8.82v8.82h-2V9.82h2Zm-1 7.82h42.331v2h-42.331v-2Zm41.331 1V9.82h2v8.82h-2Z'
                        mask='url(#h)'
                    />
                </g>
                <g stroke='#fff' opacity={0.75}>
                    <path
                        fill='#84A2FF'
                        fillOpacity={0.75}
                        d='M442.07 433.954v52.429h-56.939c.286-28.187 24.678-52.183 56.939-52.429Z'
                    />
                    <path fill='#E9E9E9' d='M442.355 490.836v-2.71h6.153v2.71zm0-57.141v-2.71h6.153v2.71z' />
                    <path d='M442.07 486.641v1.226h-56.941v-1.226z' />
                </g>
                <g stroke='#fff' opacity={0.75}>
                    <path
                        fill='#84A2FF'
                        fillOpacity={0.75}
                        d='M149.63 258.397v35.392h-37.888c.28-18.992 16.463-35.144 37.888-35.392Z'
                    />
                    <path fill='#E9E9E9' d='M150.15 296.82v-1.527h3.801v1.527zm0-38.922v-1.527h3.801v1.527z' />
                    <path strokeWidth={0.758} d='M149.752 294.164v.758h-38.134v-.758z' />
                </g>
                <g stroke='#fff' opacity={0.75}>
                    <path
                        fill='#84A2FF'
                        fillOpacity={0.75}
                        d='M160.503 172.56h35.392v-37.888c-18.992.279-35.144 16.463-35.392 37.888Z'
                    />
                    <path fill='#E9E9E9' d='M198.928 173.082h-1.527v3.801h1.527zm-38.922 0h-1.527v3.801h1.527z' />
                    <path strokeWidth={0.758} d='M196.27 172.679h.758v-38.134h-.758z' />
                </g>
                <g stroke='#fff' opacity={0.75}>
                    <path
                        fill='#84A2FF'
                        fillOpacity={0.75}
                        d='M156.266 304.275h38.49v41.198c-20.665-.28-38.241-17.891-38.49-41.198Z'
                    />
                    <path fill='#E9E9E9' d='M198.047 303.797h-1.743v-4.21h1.743zm-42.234 0h-1.743v-4.21h1.743z' />
                    <path strokeWidth={0.823} d='M195.12 304.185h.823v41.379h-.823z' />
                </g>
                <path
                    stroke='#fff'
                    d='M436.418 430.5h12m-.5 69.5v-10m-243-472v155m-6-155v158m6 76v238m-6-238v238m237.5-84.5h-162m162-4h-166m6.5 3.5v26m-6-30v30m-253.5-131.5h132m-131 5h136m-136-132h140m-141 3h141m69 1h-29m29-3h-23m-86.5 2.5v77m-.5-.5h32m-28-5h33m-32.5-71.5v72m32 0v8m0 41v7m-4-7v2m-1-43v-4'
                    opacity={0.75}
                />
                <g opacity={0.75}>
                    <path fill='#4752F8' fillOpacity={0.5} d='M1 148.276V44.211h8.819v104.065z' />
                    <path stroke='#fff' d='M.91 147.777v-4h9v4z' />
                    <mask id='i' fill='#fff'>
                        <path d='M.41 143.277V49.213h5.5v94.064H.41Z' />
                    </mask>
                    <path
                        fill='#fff'
                        d='M1.41 143.277V49.213h-2v94.064h2Zm3.5-94.064v94.064h2V49.213h-2Z'
                        mask='url(#i)'
                    />
                    <mask id='j' fill='#fff'>
                        <path d='M5.91 143.277V49.213h4.5v94.064h-4.5Z' />
                    </mask>
                    <path fill='#fff' d='M9.41 49.213v94.064h2V49.213h-2Z' mask='url(#j)' />
                    <path stroke='#fff' d='M.911 48.71v-4h9v4z' />
                    <mask id='k' fill='#fff'>
                        <path d='M9.818 148.277V44.213h8.82v104.064h-8.82Z' />
                    </mask>
                    <path fill='#4752F8' fillOpacity={0.5} d='M9.818 148.277V44.213h8.82v104.064h-8.82Z' />
                    <path
                        fill='#fff'
                        d='M18.637 44.213h1v-1h-1v1Zm0 104.064v1h1v-1h-1ZM9.818 45.213h8.82v-2h-8.82v2Zm7.82-1v104.064h2V44.213h-2Zm1 103.064h-8.82v2h8.82v-2Z'
                        mask='url(#k)'
                    />
                </g>
                <g opacity={0.75}>
                    <path stroke='#fff' d='M.91 459.977v-4h9v4z' />
                    <mask id='l' fill='#fff'>
                        <path d='M.41 455.477v-89.656h5.5v89.656H.41Z' />
                    </mask>
                    <path
                        fill='#fff'
                        d='M1.41 455.477v-89.656h-2v89.656h2Zm3.5-89.656v89.656h2v-89.656h-2Z'
                        mask='url(#l)'
                    />
                    <mask id='m' fill='#fff'>
                        <path d='M5.91 455.477v-89.656h4.5v89.656h-4.5Z' />
                    </mask>
                    <path fill='#fff' d='M9.41 365.821v89.656h2v-89.656h-2Z' mask='url(#m)' />
                    <path stroke='#fff' d='M.91 365.32v-4h9v4z' />
                    <mask id='n' fill='#fff'>
                        <path d='M9.818 460.477v-99.656h8.82v99.656h-8.82Z' />
                    </mask>
                    <path fill='#4752F8' fillOpacity={0.5} d='M9.818 460.477v-99.656h8.82v99.656h-8.82Z' />
                    <path
                        fill='#fff'
                        d='M18.637 360.821h1v-1h-1v1Zm0 99.656v1h1v-1h-1Zm-8.819-98.656h8.82v-2h-8.82v2Zm7.82-1v99.656h2v-99.656h-2Zm1 98.656h-8.82v2h8.82v-2Z'
                        mask='url(#n)'
                    />
                </g>
                <g stroke='#fff' opacity={0.75}>
                    <path d='M107.211 179.379v17.402a8.32 8.32 0 0 1-16.638 0v-17.402a1.5 1.5 0 0 1 1.5-1.5h13.638a1.5 1.5 0 0 1 1.5 1.5Z' />
                    <path
                        fill='#2D3652'
                        d='M107.211 179.379v4.555H90.573v-4.555a1.5 1.5 0 0 1 1.5-1.5h13.638a1.5 1.5 0 0 1 1.5 1.5Z'
                    />
                </g>
                <path
                    fill='#84A2FF'
                    fillOpacity={0.6}
                    stroke='#fff'
                    d='M18.256 217.566h20.166v81.017H18.256z'
                    opacity={0.75}
                />
                <path stroke='#fff' d='M271.418 430.5h5m-78-179h7m22.5-78.5v5' opacity={0.75} />
                <g stroke='#fff' opacity={0.75}>
                    <path d='M23.018 242.5h6.4c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5h-6.4a4.1 4.1 0 0 1-4.1-4.1v-14.8a4.1 4.1 0 0 1 4.1-4.1Z' />
                    <path d='M27.642 243.941h1.684c5.555 0 10.06 4.504 10.06 10.06 0 5.556-4.505 10.06-10.06 10.06h-1.684a3.1 3.1 0 0 1-3.101-3.101v-13.918a3.101 3.101 0 0 1 3.101-3.101Z' />
                    <rect width={3.089} height={2.84} x={26.584} y={252.578} rx={1.42} />
                </g>
            </svg>
        </motion.div>
    ),
);

export const InvertImageIcon = React.memo((): JSX.Element => <SVGInvertBgImage />);
export const CVATIcon = React.memo((): JSX.Element => <SVGCVATIcon />);
export const CursorIcon = React.memo((): JSX.Element => <SVGCursorIcon />);
export const MoveIcon = React.memo((): JSX.Element => <SVGMoveIcon />);
export const RotateIcon = React.memo((): JSX.Element => <SVGRotateIcon />);
export const FitIcon = React.memo((): JSX.Element => <SVGFitIcon />);
export const ZoomIcon = React.memo((): JSX.Element => <SVGZoomIcon />);
export const RectangleIcon = React.memo((): JSX.Element => <SVGRectangleIcon />);
export const PolygonIcon = React.memo((): JSX.Element => <SVGPolygonIcon />);
export const PointIcon = React.memo((): JSX.Element => <SVGPointIcon />);
export const EllipseIcon = React.memo((): JSX.Element => <SVGEllipseIcon />);
export const PolylineIcon = React.memo((): JSX.Element => <SVGPolylineIcon />);
export const TagIcon = React.memo((): JSX.Element => <SVGTagIcon />);
export const MergeIcon = React.memo((): JSX.Element => <SVGMergeIcon />);
export const GroupIcon = React.memo((): JSX.Element => <SVGGroupIcon />);
export const SplitIcon = React.memo((): JSX.Element => <SVGSplitIcon />);
export const MainMenuIcon = React.memo((): JSX.Element => <SVGMainMenuIcon />);
export const SaveIcon = React.memo((): JSX.Element => <SVGSaveIcon />);
export const UndoIcon = React.memo((): JSX.Element => <SVGUndoIcon />);
export const RedoIcon = React.memo((): JSX.Element => <SVGRedoIcon />);
export const FirstIcon = React.memo((): JSX.Element => <SVGFirstIcon />);
export const BackJumpIcon = React.memo((): JSX.Element => <SVGBackJumpIcon />);
export const PreviousIcon = React.memo((): JSX.Element => <SVGPreviousIcon />);
export const PreviousFilteredIcon = React.memo((): JSX.Element => <SVGPreviousFilteredIcon />);
export const PreviousEmptyIcon = React.memo((): JSX.Element => <SVGPreviousEmptyIcon />);
export const PauseIcon = React.memo((): JSX.Element => <SVGPauseIcon />);
export const PlayIcon = React.memo((): JSX.Element => <SVGPlayIcon />);
export const NextIcon = React.memo((): JSX.Element => <SVGNextIcon />);
export const NextFilteredIcon = React.memo((): JSX.Element => <SVGNextFilteredIcon />);
export const NextEmptyIcon = React.memo((): JSX.Element => <SVGNextEmptyIcon />);
export const ForwardJumpIcon = React.memo((): JSX.Element => <SVGForwardJumpIcon />);
export const LastIcon = React.memo((): JSX.Element => <SVGLastIcon />);
export const InfoIcon = React.memo((): JSX.Element => <SVGInfoIcon />);
export const FullscreenIcon = React.memo((): JSX.Element => <SVGFullscreenIcon />);
export const ObjectOutsideIcon = React.memo((): JSX.Element => <SVGObjectOutsideIcon />);
export const BackgroundIcon = React.memo((): JSX.Element => <SVGBackgroundIcon />);
export const ForegroundIcon = React.memo((): JSX.Element => <SVGForegroundIcon />);
export const CubeIcon = React.memo((): JSX.Element => <SVGCubeIcon />);
export const SkeletonIcon = React.memo((): JSX.Element => <SVGSkeletonIcon />);
export const ResetPerspectiveIcon = React.memo((): JSX.Element => <SVGResetPerspectiveIcon />);
export const AIToolsIcon = React.memo((): JSX.Element => <SVGAITools />);
export const ColorizeIcon = React.memo((): JSX.Element => <SVGColorizeIcon />);
export const BrainIcon = React.memo((): JSX.Element => <SVGBrain />);
export const OpenCVIcon = React.memo((): JSX.Element => <SVGOpenCV />);
export const FilterIcon = React.memo((): JSX.Element => <SVGFilterIcon />);
export const AzureProvider = React.memo((): JSX.Element => <SVGCVATAzureProvider />);
export const S3Provider = React.memo((): JSX.Element => <SVGCVATS3Provider />);
export const GoogleCloudProvider = React.memo((): JSX.Element => <SVGCVATGoogleCloudProvider />);
export const RestoreIcon = React.memo((): JSX.Element => <SVGRestoreIcon />);
export const BrushIcon = React.memo((): JSX.Element => <SVGBrushIcon />);
export const EraserIcon = React.memo((): JSX.Element => <SVGEraserIcon />);
export const PolygonPlusIcon = React.memo((): JSX.Element => <SVGPolygonPlusIcon />);
export const PolygonMinusIcon = React.memo((): JSX.Element => <SVGPolygonMinusIcon />);
export const MultiPlusIcon = React.memo((): JSX.Element => <SVGMultiPlusIcon />);
export const BackArrowIcon = React.memo((): JSX.Element => <SVGBackArrowIcon />);
export const ClearIcon = React.memo((): JSX.Element => <SVGClearIcon />);
export const ShowPasswordIcon = React.memo((): JSX.Element => <SVGShowPasswordIcon />);
export const PlusIcon = React.memo((): JSX.Element => <SVGPlusIcon />);
export const CheckIcon = React.memo((): JSX.Element => <SVGCheckIcon />);
export const GuideIcon = React.memo((): JSX.Element => <SVGGuideIcon />);
export const DurationIcon = React.memo((): JSX.Element => <SVGDurationIcon />);
export const FrameCountIcon = React.memo((): JSX.Element => <SVGFrameCountIcon />);
export const FramesIcon = React.memo((): JSX.Element => <SVGFramesIcon />);
export const ConflictIcon = React.memo((): JSX.Element => <SVGConflictIcon />);
export const ShowGroundTruthIcon = React.memo((): JSX.Element => <SVGShowGroundTruthIcon />);
export const JoinIcon = React.memo((): JSX.Element => <SVGJoinIcon />);
export const SliceIcon = React.memo((): JSX.Element => <SVGSliceIcon />);
export const PaidIcon = React.memo((): JSX.Element => <SVGPaidIcon />);
