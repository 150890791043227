// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import MDEditor from '@uiw/react-md-editor';
import CVATLoadingSpinner from 'components/common/loading-spinner';

function ReadOnlyGuidePage(): JSX.Element {
    const fetching = false;
    const value = `
# Contents
### Video Guides
- [Video Guide #1](https://files.byson.com.au/dl/C45vbGC3Ra)
- [Video Guide #2](https://files.byson.com.au/dl/Tfgc9dsLjq)

### Usage & Features
- [Zooming & Navigating](#zooming--navigating)
- [Key Bindings](#key-bindings)
- [Polygon Tool](#polygon-tool)
- [Box Tool](#box-tool)
- [Copy Tool](#copy-tool)
- [Split Polygon Tool](#split-polygon-tool)


### Label Specific Guides
- [Wall Guide](#wall-guide)
- [Footprints Guide](#footprint-guide)
- [Wall Openings](#wall-openings)
  - [Windows](#window-guide)
  - [Doors](#door-guide)
  - [Cavity](#cavity-guide)
- [Plumbing](#plumbing-labels)
  - [Sink](#sink-guide)
  - [Vanities](#vanity-guide)
  - [Shower](#shower-guide)
  - [Toilet](#toilet-guide)
  - [Bathtub](#bathtub-guide)
- [Robe Guide](#robe-guide)
- [Stair Guide](#stair-guide)
- [Other Labels](#other-labels)
  - [Title](#title-guide)
  - [Dimension](#dimension-guide)
  - [Scale](#scale-guide)
  - [Area](#area-guide)

# Usage & Features
### Zooming & Navigating
**Navigating**
- While zoomed in use the scroll wheel to scroll vertically within the image
    - Note: This will also work on a touch pad when using 2 fingers for scroll
- To scroll horizontally hold down shift while using the scroll wheel
- Note: If you have a dedicated horizontal scroll wheel on your mouse, or are using a touchpad with
2 finger gesture to scroll you will not need to hold down the shift key\
- You can also select the drag icon (below) from the left hand panel to move around the image by dragging it.
this cannot be done while drawing.\
![drag](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/drag.png)\
**Zooming**
- To Zoom hold down the Control (Windows) or Command (Mac) key while using the scroll wheel / touchpad with 2 fingers
  - When zooming in the image will be zoomed towards your mouse,
   move the cursor to where you want to zoom in before zooming
  - When zooming out the image will be zoomed away from your mouse,
  move the cursor to the opposite side of where you want to focus before zooming
- Select the icon below from the left hand panel and then draw the area you want to zoom in on.\
![region](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/region.png)\
**Reset Zoom**\
Click the icon below from the left hand panel or double-click the image while no drawing tool is selected\
![reset](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/full-image.png)\
**Invert Image Colour**\
Click the icon below from the left hand panel or press 'I'\
![invert](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/invert.png)\
**Rotate Image**\
Click the icon below from the left hand panel, then select the direction to rotate.\
![rotate](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/rotate.png)

---
### Key Bindings
You must be focused on the image / canvas for these to work. If there is a dropdown that is
focussed it will type there first. Ensure you have hit enter or clicked on the image after drawing.\
**P** - Will select / deselect the polygon tool \
**B** - Will select / deselect the bounding box / Rectangle Tool \
**I** - Will invert the image colours

---
### Polygon Tool
1) Press 'P' or select the polygon tool
2) Click on the canvas placing points to define your shape
3) To finish hit the 'Enter' key or your first point
4) On finishing the dropdown for your image will be selected - start typing the label
name until the label you want is selected, then press enter.
5) The polygon tool will remain active until you press 'P' again or select a different tool.

**Add points**\
By selecting the cursor / unselecting polygon and hover on the shape near the
edge of the line until the plus icon appears next to your cursor.
Then Drag it where it needs to go.

**Delete points**
By selecting the cursor / unselecting polygon and hover over the polygon and right-click the icon to delete.
Select delete point from the menu.

---
### Box Tool
1) Press 'B' or select the bounding box / rectangle tool
2) Click on the image in your first corner
3) Click again on the image to set the opposite corner
4) On finishing you can change the class with the dropdown for your new item
5) The box tool will remain active until you press 'B' again or select a different tool.
6) If you need to rotate the bounding box deselect the rectangle tool, and hover over your box - a
white dot will show above it, click and drag the white circle to rotate your bounding box
---
### Copy Tool
This allows you to copy an annotation you've made to annotate a similar object.\
A common example where you might use this is a door, bath, or vanity.
Unfortunately you cannot rotate polygons at this stage, so you would at least need to draw one of each orientation.
You can then edit the points afterward if the item is a bit different.
1) Right-click a polygon you wish to copy - A common item to do this on will be doors
2) Select the kebab menu (three dots) to the right of the label dropdown
3) Select 'Make a copy'
4) Move the outline to the new location and click to place
![Copy Tool](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/Copy.gif)
---
### Split Polygon Tool
This allows you to split a polygon into separate polygons and edit each separately. Some good use cases are:
- Correcting a footprint for multiple dwellings together
- Correcting party wall when it has been drawn as one
- Needing to change the label of part of an item
- Removing excess area that has been annotated
How to use it:
1) Select the split polygon tool on the left hand side
2) Select the polygon you wish to split
3) Click once outside the polygon where you want to start the cut
4) Click at least once inside the polygon, you can select multiple points if you need to cut a complex shape
5) Finish by clicking outside the polygon where the cut should end
6) If you need to delete or change the label on the new polygon

![SplitExample](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/split.gif)
![SplitRelabel](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/split-label.gif)
![SplitDelete](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/split-delete.gif)
---
# Label Specific Guides
# Wall Guide
External walls will be walls that form the perimeter
- All the layers of the layers of the wall should be included
  - It is incorrect to separate the frame layer as internal and the cladding as external
- Keep as close as possible to the outline, but with the outline included
- Break the annotation for windows / doors / garage doors etc
- Piers are included

**Good examples:**\
![goodwall](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/WallGood1.png)\
External wall cladding and frame layer are drawn as one\
Annotation is close to but including the outline\
![partywall](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/WallGood2.png)\
If a  party wall (a wall that divides 2 units) has 2 main wall layers draw them as two walls

**Bad examples:**\
![BadWall](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/WallBad1.png)\
Cladding is marked as external while frame layer is internal\
Both should be the one external wall\
Lines are not close to the wall

**Voids - Bad**\
![BadVoid](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/Bad+-+Void.png)\
Annotations includes void space\
Lines are messy / inaccurate

**Voids - Good**\
![GoodVoid](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/GoodVoid.png)\
Annotations go around the void and only include the wall\
Annotation is close to but including the outline

**Lower Floors - Bad**\
![BadLower](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/Bad+-+Lowers.png)\
Lower floor is marked as external\
External FF wall is marked as internal

**Lower Floors - Good**\
![GoodLower](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/Good+Lowers.png)\
- Lower walls / Roof lines are ignored
- External walls are correctly marked

**Balustrades - Bad**
Generally balustrades should only be annotated if they are drawn the same as the other internal / external walls
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bad-balustrade.png)\
**Bad** The stair balustrade is much thinner than the other walls - don't include

# Footprint Guide
Footprints define the building area, this area should be the external walls & windows and the areas they contain.\
Outward opening doors should not be included, follow the wall line.\

**Each unit / dwelling should have its own footprint**
- Where 2 units are attached 2 separate polygons **must** still be drawn with the two
edges down the middle of the units following the party-wall.
- Garage may have a second footprint if it is a standalone structure or connected to a different unit

**Inclusions**
- Garages
- Sun Rooms
- Wall protrusions

**Exclusions**\
Outdoor areas should not be included in the footprint these include:
- Porch
- Deck
- Veranda
- Balcony
- Alfresco (unless completely enclosed with windows)
- Terrace
- Lower roof areas / building area from the floor below
- Area under eaves (follow the wall line not the roof-line)
- Carport

**Examples**\
![footprint-good.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/footprint-good.png)\
**GOOD:** Each unit has its own annotation\
**GOOD:** The footprint follows wall protrusion areas at the front of the master bed
---
![footprint-good.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/footprint-good-doors.png)\
**GOOD:** the annotation follows the wall line and does not come out for how the door / window is drawn
---
![footprint-bad.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/footprint-bad.png)\
**BAD:** The area indicated with red is part of the floor - the footprint should come in sticking to the walls
---
![footprint-bad.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/footprint-bad-doors.png)\
**BAD:** The footprint area steps out where the door would open - it should follow the wall line
---
![footprint-bad.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/footprint-bad-areas.png)\
**BAD:**  Outdoor areas should be excluded - see list above
# Wall Openings
## Window Guide
- The Window class is for glazing - this includes sliding and bifold doors if they are fully glazed.
- Generally this will be all sliding doors / bifold / multifold doors on an external wall.
- Swinging doors (even when glazed) on external walls will still be marked as doors.

**Examples**\
![window-good](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-good.png)\
**GOOD:** A window with 2 sashes is drawn as one window\
**GOOD:** The windows polygon encompasses the whole representation -
even where the window sticks out beyond the external wall
---
![window-good](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-good1.png)\
**GOOD:** Even though this is a sliding door - as it's on an external wall it is classed as a window
---
![window-bad](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-bad2.png)\
**GOOD:** Even though this is a bifold door - as it's on an external wall it is classed as a window
**Bad:** the polygon follows should follow the door on the outside edge at least\
![window-good](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-good3.png)\
**GOOD:** Even though this is a bifold door - as it's on an external wall it is classed as a window
**GOOD:** the polygon follows the inner wall line and outder edge of the door
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-multi-tight.png)\
**GOOD:** \
---
![window-good](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-bad1.png)\
**BAD:** This is unnecessary - keep to the width of the wall as it minimum\
![window-good](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/window-good2.png)\
**GOOD:** An exeception to the bad example above is where the doors are packed very tightly -
the group can be drawn as one - but the full opening should be included

## Door Guide

**Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door-good.png)\
**GOOD:** Follows the arc of the door well\
**GOOD:** includes the jambs(when shown)\
**GOOD:** includes the opening space between the walls\
---
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door-bad-1.png)\
**BAD:** Should include the jambs and wall opening\
---
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/double-door-good.png)\
**Good:** Double doors can be drawn as one\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/double%20door%20-%20good.png)\
**Good:** Double doors should also be annotated when not drawn fully open
---
**Exclusions**
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door-bad-cabinetry.png)\
**Unnecessary:** No need to draw doors when shown for cabinetry / refrigerators
unless drawn with the same detail as the rest of the doors
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door-cabinetry%20-%20ok.png)\
**Good:** Pantry(pty) doors do not need to be drawn as they are not drawn like the doors on the internal/external walls
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door-shower.png)\
**Unnecessary:** Shower screen doors do not need to be annotated
---
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door-pivot.png)\
**Good:** Pivot doors should include both areas of swing like this
---
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/door%20-bad.png)\
**BAD:** While the polygon is correct a multifold door when it is on an external wall should be classified as a window\
---
## Cavity Guide
Cavity sliding doors are sliding doors typically on internal wall,
where the door can slide into a cavity within the wall.
This class will also include barn doors - that slide on the face of an internal wall.\
Generally a cavity slider will have a section in the wall - the wall and cavity annotation should overlap
here and both should be the full width of the wall.

**Good Examples:**\
![cav%20good.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cav%20good.png)\
![cavity-1.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cavity-arrow-good.png)\
![cavity-2.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cavity-arrow-good-2.png)\
![cavity-3.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cavity-good.png)\
Follow the shape of the arrows when drawn this way

![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cavity-5.png)\
Barn door - include as much as possible
---
**Bad Examples:**\
![cav-bad-1.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cav-bad-1.png)\
Include the area in the wall when shown

![cav-bad-1.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cav-bad-2.png)\
Cavity should include the full thickness of the wall where it is inside the wall

![cavity-robe-bad](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cavity-robe-bad.png)\
Don't include robe doors

![bad-cavity-2.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bad-cavity-2.png)\
This is just an opening - dotted lines like this indicate an opening **NOT** a cavity unless there is also label
such as S/D (Sliding Door), CS (Cavity Slider) etc. Note: A Label of Opening such as this or BH (Bulkhead) -
is **NOT** a cavity slider.

---
# Plumbing Labels

## Sink-guide
Sink label is for sinks and cook tops (stove tops / hot plates)

Guidelines:
- Follow the shape of the sink / cook top
  - **DON'T** include the rest of the bench top (this is different to vanities)
- Draw double sinks as one object
- Include the side drain if drawn with one
- Sinks are generally in the following areas:
  - Kitchen
  - Pantry / WIP / Butlers
  - Laundry
  - Alfresco / BBQ
  - Bar / Kitchenette
- Sinks are generally **NOT** in the following areas:\
(If it's in these areas it's probably a vanity)
  - En Suite / Ens
  - Pdr / WC
  - Bath

**Good Examples**\
![sink](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/sink.png)\
![cooktop](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/cook-top.png)\
![double-sink](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/double-sink.png)\
![double-sink-with-drain](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/double-sink-with-drain.png)\
**Bad Examples**\
![sink-whole-benchtop](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/sink-whole-benchtop.png)\
**Bad** Just the sink area - not the benchtop\
![sink-dishwasher](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/sink-dishwasher.png)\
**Bad** This is a dishwasher\
![sink-no-corner](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/sink-no-corner.png)\
**Bad** Follow the corners
---
## Vanity Guide
Vanity label is for basins AND the cabinetry containing them

Guidelines:
- Follow the shape of the vanity & basin - inset basins may prodrude from the cabinetry
- **DO** Include the full cabinet - not just the basin
- Vanities are generally in the following areas:\
  - En Suite / Ens
  - Pdr / WC
  - Bath
  - Places where you would likely need to wash your hands
- Vanities are generally **NOT** in the following areas:\
  (If it's in these areas it's probably a sink)
  - Kitchen
  - Pantry / WIP / Butlers
  - Laundry
  - Alfresco / BBQ
  - Bar / Kitchenette


**Good Examples**\
![vanity](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/vanity.png)\
![inset-basin-good](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/inset-basin-good.png)\
![wall-hung-vanity](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/wall-hung-vanity.png)\
![vanity-long-cabinet](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/vanity-long-cabinet.png)\
**Bad Examples**\
![vanity-basin-only](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/vanity-basin-only.png)\
**Bad** Include the cabinetry - not just the basin\
![vanity-bad-inset](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/vanity-bad-inset.png)\
**Bad** Follow the basin, back to the cabinetry
---
## Shower Guide
 - Showers may not be explicitly labelled, either way we should annotate them\
 **Include the full shower area, not just the shower head**
 - May be labelled as just a base, or a square with a dashed X
 - May or may not show the shower head / drain
 - -May have a bath tub within

**Good Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/shower-1.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/shower-2.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/shower-3.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/shower-4.png)\
**Bad Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/shower-head-bad.png)\
**Bad** Include the shower area not just the head\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/shower-door.png)\
**Bad** Include the shower area - don't worry about the door
---
## Toilet Guide
- Toilets are pretty straight forward but can be drawn many different ways see example below

**Good Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/toilet-1.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/toilet-2.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/toilet-3.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/toilet-4.png)\
**Bad Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/toilet-bad.png)\
**Bad** Follow the shape of the toilet\

---
## Bathtub Guide
- Bathtubs are pretty straight forward but can be drawn many different ways see example below
- Follow the shape of the bathtub, may be rounded

**Good Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bath-1.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bath-2.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bath-3.png)\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bath-4.png)\
**Bad Examples**\
![](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bath-bad.png)\
**Bad** Follow the shape of the bathtub\
---
# Robe Guide
- Robe class is used to annotate the shelf / dresser part of a robe if shown
- If no details are shown inside the robe - just follow the wall line
- Robe Labels:
  - Robe
  - WIR
  - BIR
  - Dresser
  - His / Hers
- Do not annotate if they are labelled:
  - Pantry / Pty / WIP
  - Linen / WIL
  - Storage
  - Desk
  - Study
- Robes are not always labelled but should be a storage area **attached to a bedroom** (Not Kitchen / Ldry / Other)

**Good Examples**\
![robe-1.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/robe-1.png)\
![robe-2.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/robe-2.png)\
![robe-3.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/robe-3.png)\
![robe-4.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/robe-4.png)\
**Bad Examples**\
![robe-pantry.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/robe-pantry.png)\
**Bad** This is a pantry not a robe - note that it connects to a kitchen.
---
# Stair Guide
Coming Soon
---
# Other Labels

---
## Title Guide
Drawn as a Rectangle / Bounding box
- Every plan should have a primary title block - this will include things like:
  - The architect / client information
  - What the plan is
  - Often scale
  - Often Revisions
**Do not include general notes**
- Many plans will have a section title block - this should also be annotated

**Main Title Block Example:**\
![main-title.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/main-title.png)\
**Section Title Block Example:**\
![section-title.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/section-title.png)
---
## Dimension Guide
Drawn as a Rectangle / Bounding box
- Should only annotate large blocks of dimensions
- Do not annotate one off/ isolated lines

**Good Example:**\
![dimensions.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/dimensions.png)\
**Bad Example:**\
![bad-dimensions.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/bad-dimensions.png)
---
## Scale Guide
Drawn as a Rectangle / Bounding box
- May be in the main title block
- May be on a section title block
- Should include a ratio eg 1:100 or 1:50
- Include the word scale if shown
- Include the page size if shown  eg "@ A3"

**Good Example:**\
![scale-1.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/scale-1.png)\
![scale-2.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/scale-2.png)
---
## Area Guide
Drawn as a Rectangle / Bounding box
- Should include the m2 or Sq for different units / areas of the unit
- Not wattage calculations / window or door schedules / other tables

**Good Example:**\
![area.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/area.png)\
**Bad Example:**\
![area-wattage.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/area-wattage.png)\
**BAD:** This is a wattage calculation table\
![area-window-schedule.png](https://s3.ap-southeast-2.amazonaws.com/draw.doxle.com/CVAT-Examples/area-window-schedule.png)\
**BAD:** This is a window schedule
---
    `;
    return (
        <Row
            justify='center'
            align='top'
            className='cvat-guide-page'
        >
            { fetching && <CVATLoadingSpinner /> }
            <Col md={22} lg={18} xl={16} xxl={14}>
                <div className='cvat-guide-page-editor-wrapper'>
                    <MDEditor
                        visibleDragbar={false}
                        data-color-mode='light'
                        value={value}
                        preview='preview'
                        height='100%'
                        hideToolbar
                        fullscreen
                    />
                </div>
            </Col>
        </Row>
    );
}

export default React.memo(ReadOnlyGuidePage);
