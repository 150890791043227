// Copyright (C) 2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT
import { Col, DatePicker, Input, InputNumber, Modal, notification, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { getCore, Job, User } from 'cvat-core-wrapper';
import { getCVATStore } from 'cvat-store';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import './styles.scss';
import { createPaymentAsync } from 'actions/payment-actions';
import { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { produce } from 'immer';

export type PaymentData = {
    annotator: User | undefined;
    jobId: number | undefined;
    amount: `${number}` | '';
    description: string;
    final: boolean;
    date: string | null;
};

export type Props = {
    job: Job | undefined;
    onSuccessUpdatePayment: () => void;
    onClosePaymentModal: () => void;
};

const core = getCore();
const initialUsersStorage: {
    storage: Record<
        string,
        {
            promise: Promise<User[]>;
            timestamp: number;
        }
    >;
    get(userID?: number, organizationSlug?: string): Promise<User[]>;
} = {
    storage: {},
    get(userID?: number, organizationSlug?: string): Promise<User[]> {
        if (typeof userID === 'undefined') {
            return Promise.resolve([]);
        }

        const key = `${userID}_${organizationSlug || ''}`;
        const RELOAD_INITIAL_USERS_AFTER_MS = 300000;
        if (key in this.storage && Date.now() - this.storage[key].timestamp < RELOAD_INITIAL_USERS_AFTER_MS) {
            return this.storage[key].promise;
        }

        this.storage[key] = {
            promise: core.users.get({ limit: 10, is_active: true }),
            timestamp: Date.now(),
        };

        this.storage[key].promise.catch(() => {
            delete this.storage[key];
        });

        return this.storage[key].promise;
    },
};

function PaymentJobModal(props: Props) {
    const { job, onClosePaymentModal, onSuccessUpdatePayment } = props;
    const [initialUsers, setInitialUsers] = useState<User[]>([]);
    const [paymentData, setPaymentData] = useState<PaymentData>({
        jobId: job?.id,
        amount: '0.00',
        description: '',
        final: true,
        date: new Date().toISOString().substring(0, 10),
        annotator: initialUsers.find((user) => user.id === job?.assignee?.id),
    });
    const dispatch = useDispatch();
    const state = getCVATStore().getState();
    const descriptionInputRef = useRef<Input>(null);
    const onSubmitPayment = async (): Promise<any> => {
        try {
            await dispatch(
                createPaymentAsync({ ...paymentData, description: descriptionInputRef.current?.input.value ?? '' }),
            );
            onSuccessUpdatePayment();
            onClosePaymentModal();
        } catch (error) {
            notification.error({
                message: 'Could not update payment from the server',
            });
        }
    };
    const userID = useMemo(() => state.auth.user?.id, [state.auth.user?.id]);
    const organizationSlug = useMemo(() => state.organizations.current?.slug, [state.organizations.current?.slug]);
    useEffect(() => {
        initialUsersStorage.get(userID, organizationSlug).then((result: User[]) => {
            if (result) {
                setInitialUsers(
                    produce((draft) => {
                        draft = result;
                        return draft;
                    }),
                );
                setPaymentData((old) =>
                    produce(old, (draft) => {
                        draft.jobId = job?.id;
                        draft.annotator = result.find((user) => user.id === job?.assignee?.id) as User | undefined;
                        return draft;
                    }),
                );
            }
        });
    }, [userID, organizationSlug, job?.id, job?.assignee?.id]);

    return (
        <Modal
            visible={job !== undefined}
            onCancel={onClosePaymentModal}
            onOk={onSubmitPayment}
            title='Job Payment'
            className='cvat-payment-job-modal'
        >
            <Row align='middle'>
                <Col span={5}>
                    <Text strong>Project</Text>
                </Col>
                <Col>{job?.projectId}</Col>
            </Row>
            <Row align='middle'>
                <Col span={5}>
                    <Text strong>Job</Text>
                </Col>
                <Col>{paymentData.jobId}</Col>
            </Row>
            <Row align='middle'>
                <Col span={5}>
                    <Text strong>Annotater:</Text>
                </Col>
                <Col>
                    {paymentData.annotator?.username}
                    {paymentData.annotator?.email && <Text type='secondary'>{paymentData.annotator?.email}</Text>}
                </Col>
            </Row>
            <Row align='middle'>
                <Col span={5}>
                    <Text strong>Description:</Text>
                </Col>
                <Col flex={1}>
                    <Input
                        placeholder='payment description...'
                        defaultValue={''}
                        ref={descriptionInputRef}
                        width='100%'
                    />
                </Col>
            </Row>
            <Row align='middle'>
                <Col span={5}>
                    <Text strong>Amount:</Text>
                </Col>
                <Col>
                    <InputNumber
                        prefix='$'
                        value={paymentData.amount}
                        onChange={(value: `${number}` | '' | null) => {
                            if (value === null) return;
                            setPaymentData((prev) => ({ ...prev, amount: value }));
                        }}
                    />
                </Col>
            </Row>
            <Row align='middle'>
                <Col span={5}>
                    <Text strong>Date:</Text>
                </Col>
                <Col>
                    <DatePicker
                        onChange={(value: Moment | null, dateString: string) => {
                            setPaymentData((prev) => ({
                                ...prev,
                                date: value === null ? null : new Date(dateString).toISOString().substring(0, 10),
                            }));
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
}

export default PaymentJobModal;
